.btn-custom {
background-color: #C2FFAC;
border: none;
border-color: #C2FFAC;
border-radius: 20px;
color: #000000;
padding: 10px;
}

.btn-custom:hover {
background-color: #A4FF8F;
border-color: #A4FF8F;
color: #000000;
}

.custom-card {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 20px;
    height: 450px;
    margin-bottom: 20px;
}

.school-custom-card {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.card-btn {
    margin:auto
}

.list-unstyled li a{
    text-decoration: none;
    color: #002147;
}


/* school side bar */
.school-sidebar {
    width: 250px;
    background-color: #f0f0f0;
    /* padding: 20px; */
    height: 95vh; /* Adjust the height as needed */
  }
  
  .sidebar h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-menu li {
    margin-bottom: 10px;
  }
  
  .sidebar-menu a {
    display: block;
    text-decoration: none;
    color: #555;
  }
  
  .sidebar-menu a:hover {
    color: #000;
  }
  
  
  .sidebar-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .sidebar-item:hover {
    background-color: #e0e0e0;
  }
  
  .sidebar-item-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .sidebar-item-name {
    font-size: 14px;
    color: #333;
  }
  